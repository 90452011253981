<template>
    <div>
        <div class="info">
            <p>登记证信息</p>
            <van-cell-group>
                <van-field label="车辆所有人：" v-model="isData.owner" />
                <van-field label="车牌号码：" v-model="isData.number" />
                <van-field label="品牌：" v-model="isData.brand" />
                <van-field label="型号：" v-model="isData.model" />
                <van-field label="发动机号：" v-model="isData.engine" />
                <van-field label="车辆识别代码：" v-model="isData.vin" />
                <van-field label="登记证日期：" v-model="isData.date" />
            </van-cell-group>
        </div>
         <van-button class="content-btn" type="info" size="large" @click="submit">完成</van-button>
        <van-popup  :closeable="true"  v-model="show" class="popup" :close-on-click-overlay="false">
              <p style="text-align: center; ">修改长宽高</p>
              <hr>
              <van-field label="长：" v-model="gabarite1" />
              <van-field label="宽：" v-model="gabarite2" />
              <van-field label="高：" v-model="gabarite3" />
              <div class="btn-ok">
                  <!-- <van-button @click="show= false">取消</van-button> -->
                  <van-button type="info" @click="isSubmit">确定</van-button>
              </div>
        </van-popup>
        <van-popup  :closeable="true"  v-model="voiceShow" class="popup" :close-on-click-overlay="false">
              <p style="text-align: center; ">是否语音播报</p>
              <hr>
              
              <div class="btn-ok">
                  <van-button @click="voiceShow= false">取消</van-button>
                  <van-button type="info" @click="yuying">确定</van-button>
              </div>
        </van-popup>
        <audio :src="voice" id="audio"  ref="au"></audio>
    </div>
</template>

<script>
import { Toast } from 'vant';
import { is_post } from "../../Http/api.js";
import { deleteCookie } from '@/utils/env'
import music from '../../components/music.vue'
import { encipherMent, encryptDecode } from '@/utils/encrypt'
    export default {
        components: {
            music,
        },
        data() {
            return {
                fileList1: [],//行驶证正面照片
                fileList2: [],//反面照片
                isData:{},//证明识别数据
                versoData:{},//反面数据
                registratioCard:"",//证明照片
                reverseUrl:'',//反面照片链接
                show:false,//修改长宽高
                gabarite1:"",//涨
                gabarite2:"",//宽
                gabarite3:"",//高
                voice:'',//语音链接
                voiceShow:false,//打开点击语音
                wximagelocalids:[],//判断上传张数
                toast:null,//转圈圈
            }
        },
        computed: {
            gabarite() {
                return (n)=>{
                    let a = ""
                    if(Object.keys(n).length!=0){
                        a = n.gabarite1 + '-' + n.gabarite2 + '-' + n.gabarite3 + 'mm'
                    }else{
                        a = 0 +'mm'
                    }
                    return a
                }
            },
        },
        mounted () {
            if(this.isWechat){
                this.wcConfig();
            }
        },
        watch: {
            wximagelocalids:{
                handler(newValue, oldValue){
                     if(newValue.length > 0 ){
                    this.toast = Toast.loading({
                        duration: 0, // 持续展示 toast
                        forbidClick: true,
                        message: "上传中..."
                    });
                    this.wxUpload(newValue[0])
                }else{
                    this.toast.clear();
                }
                },
                deep: true,
            }
        },
        methods: { 
            isPlay(n){
                var toast = Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: "加载中..."
                });
                console.log(n)
                let text = `车牌号：${n.number}，所有人：${n.owner?n.owner:'暂无'}，车辆识别代码：${n.vin}`
                this.$http.post('index.php/wechat/makeYuYin',{text}).then(res=>{
                    
                    this.voice = res.data.data
                    toast.clear()//清除加载效果
                    var u = navigator.userAgent;
                    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
                    if(isiOS){
                        this.voiceShow = true
                    }else{
                        this.$nextTick(()=>{
                            this.$refs.au.play()
                        })
                    }
                })
            },
            yuying(){
                this.$refs.au.play()
                 this.voiceShow = false
            },
           
            isPopup(){
                this.show=true
                console.log(this.versoData)
                this.gabarite1  = this.versoData.gabarite1 
                this.gabarite2  = this.versoData.gabarite2 
                this.gabarite3  = this.versoData.gabarite3 
            },
           isSubmit(){
               this.versoData.gabarite1 = this.gabarite1 
               this.versoData.gabarite2 = this.gabarite2 
               this.versoData.gabarite3 = this.gabarite3 
               this.show=false
           },
            async submit(){
                try{
                    
                    let obj = {
                        ...this.isData,
                        registratioCard:this.registratioCard,
                        identificationCode:this.isData.vin,
                        engineCode:this.isData.engine,
                    }
                    this.$delete(obj,'engine')
                    this.$delete(obj,'vin')
                     let data = encipherMent( JSON.stringify(obj))
                    let param = new URLSearchParams();
                    param.append("value",data);
                    const res = await is_post('index.php/vehicles_upload/vehicleInformation',param).then(res=>res)
                   console.log(res)
                    if(res.code == 0 ){
                       this.gouot()
                        this.$toast.success(res.msg)
                    }
                }
                catch{

                }
                   
            },
            gouot() {
                this.$router.push('/Recognition')
            },
            //删除
            deleteimg(){
                this.isData = {}
                return true
            },
            //上传照片
           uploadershang(file){
                var toast = Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: "加载中..."
                });
                    let params = new FormData(); //创建form对象
                    params.append("file", file.file);
                   this.$http.post("/index.php/index/upload/index", params)
                    .then((res) => {
                        
                    if (res.data.code == 0) {
                         toast.clear()//清除加载效果
                        //登记证
                        let url = 'index.php/index/Image_Recognition/vehicleRegistrationOcr'
                        this.registratioCard = res.data.data
                        this.recognition(url,{path:res.data.data},1)
                       
                    } else if(res.data.code == -1){
                        deleteCookie('token')
                        this.$toast.fail(res.data.msg)
                        this.$router.push('/')
                    }else{
                        this.registratioCard = ''
                        this.fileList1 = []
                         toast.clear()//清除加载效果
                        this.$toast.fail(res.data.msg)
                    }
                    }).catch((err)=>{
                        this.registratioCard = ''
                        this.reverseUrl = ''
                        this.fileList1=[],//行驶证正面照片
                         toast.clear()//清除加载效果
                        this.$toast.fail('网络开小差了，请稍后重试！')
                    })
               
           },
        },
    }
</script>

<style lang="scss" scoped>
.content{
    padding: 10px;
    text-align: center;
    .content-up{
        
        padding: 10px;
      
    }
}

.info{
    margin-top: 20px;
    padding: 10px;
}

.van-uploader__preview-image{
    width: 100% ;
    height: 100px ;
}
  .popup{
    width: 80%;
    padding: 20px;
    .btn-ok{
        width: 100%;
        display: flex;
        .van-button{
            flex: 1;
        }
    }
}
.music-xsz{
    position: relative;
}
</style>