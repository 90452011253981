<template>
    <div class="popupWas">
        <p style="color:red;font-size: 12px;">
            注：系统中存在以下同车牌车辆，您当前输入的VIN码为{{vin}}，请核对是否正确，如果正确，请点击“直接提交”，如不正确，请点击“取消”后修改车架号，或者勾选下表中的正确车辆并点击“资料更新”
        </p>
        <ul class="popupWas-ul">
            <li class="popupWas-ul-li" v-for="(item,index) in repetition" :key = "index">
                <div style="flex:1;display: flex; align-items: center; justify-content: center;">
                    <van-checkbox-group v-model="checkbox" :max="1">
                        <van-checkbox :name="item.vin"></van-checkbox>
                    </van-checkbox-group>
                </div>
                <div style="flex:2;"> 
                    <van-image
                    width="100px"
                    height="100px"
                    :src="item.VehicleImages0?item.VehicleImages0:imgs"
                    />
                </div>
                <div style="flex:3;"> 
                    <p>自定义编号：<span> {{item.defined_num?item.defined_num:'暂无'}}</span></p>
                    <p>车牌号：<span style="font-size: 12px;"> {{item.carNumberPlate}}</span></p>
                    <p>车架号：<span style="font-size: 12px;"> {{item.vin}}</span></p>
                </div>
            </li>
        </ul>
        <div class="bombtn">
            <van-button   @click="$emit('colosepopup')">取消</van-button>
            <van-button type="primary"  @click="$emit('comeWei')">直接提交</van-button>
            <van-button type="info" @click="upDate">资料更新</van-button>
        </div>
    </div>
</template>

<script>
    export default {
        props:['repetition','vin'],
        data() {
            return {
                imgs:require('../../assets/zw.jpg'),
                checkbox:[],
            }
        },
       
        methods: {
            upDate(){
                if(this.checkbox.length!=0){

                    this.$emit('upDate',this.checkbox)
                }else{
                    this.$toast.fail('请选择要更新的数据！')
                }
            }
        },
        
    }
</script>

<style lang="scss" scoped>
.popupWas{
    width: calc(100% - 20px);
    padding: 10px;
    .popup-row{
        background: #eee;
        p{
            margin: 0;
            line-height: 35px;
            
        }
    }
}
.popupWas-ul{
    box-shadow: 0 2px 2px 0px #eee;
  
    .popupWas-ul-li{
        display: flex;
        margin-bottom: 15px;
        
    }
}
.bombtn{
    position: fixed;
    width: calc(100% - 20px);
    height: 130px;
    bottom: 0%;
    z-index: 999;
    background: #fff;
    text-align: center;
    .van-button{
        margin: 10px;
        margin-top: 30px;
        width: 120px;
    }
}
</style>