<template>
    <div>
         <div class="header">
             <span @click="gouot"></span>
             <span>车辆信息</span>
             <span @click="islogout">退出</span>
        </div>
        <!-- <div class="h_content">
            <van-field
                readonly
                clickable
                name="picker"
                v-model="showType"
                label="选择类型："
                placeholder="点击选择类型"
                @click="show = true"
                />
            <van-popup v-model="show" position="bottom">
                <van-picker show-toolbar value-key="name" :columns="columns" @cancel="show = false" @confirm="onConfirm" />
            </van-popup>
        </div> -->
        <!-- <hr> -->
        <div class="component">
            <div >
                <!-- <Register /> -->
            </div>
            <div >
                <DrivingLicense />
            </div>
        </div>
        <div>

        </div>
    </div>
</template>

<script>
import Register from './Register.vue'
import DrivingLicense from './drivingLicense.vue'

    export default {
        components: {
            Register,DrivingLicense
        },
        data() {
            return {
                show: false,
                columns:[
                    {id:1,name:'行驶证'},
                    {id:2,name:'登记证'},
                ],
                showType:"行驶证",//选择的文字
                showTypeId:1,//类型选择
            }
        },
        methods: {
            gouot() {
                this.$router.push('/Recognition')
            },
            onConfirm(e,i){
                this.showType = e.name
                this.showTypeId = e.id
                this.show = false
            }
        },
    }
</script>

<style lang="scss" scoped>
.header {
    position: fixed;
    top: 0;
    z-index: 9;
    width: calc(100% - 40px);
    padding: 0 20px;
    background-color: cadetblue;
    height: 1rem;
    line-height: 1rem;
    color: white;
    font-size: 0.4rem;
    display: flex;
    justify-content: space-between;
    span {
        display: inline-block;
    }
    span:nth-child(1) {
        width: 30px;
        height: 30px;
        background-image: url(../../assets/u39.png);
        background-size: 100%;
        margin-top: 8px;
    }
}
.h_content{
    padding: 10px;
    margin-top: 50px;
}
.component{
    // margin-top: 50px;
}
</style>