<template>
    <div :class="[isMusic == 'true'?'music-T':'music-s']" @click="stopMusic">
        <van-icon v-if="isMusic == 'true'" name="music" />
        <span v-if="isMusic != 'true'" class="inclined"> </span>
        <van-icon v-if="isMusic != 'true'" name="music-o" />
    </div>
</template>

<script>
    export default {
        data() {
            return {
                isMusic:false
            }
        },
        computed: {
            stopmusic() {
                return window.localStorage.getItem('stopMusic')
            }
        },
        mounted () {
            this.isMusic = window.localStorage.getItem('stopMusic') || false
        },
        methods: {
            stopMusic() {
                if(window.localStorage.getItem('stopMusic') == 'true'){
                    window.localStorage.setItem('stopMusic',false)
                }else {
                    window.localStorage.setItem('stopMusic',true)
                }
                this.isMusic = window.localStorage.getItem('stopMusic')
                console.log(this.isMusic);
            }
        },
    }
</script>

<style lang="scss" scoped>
.music-T{
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 25px;
    animation:turn 1s linear infinite;
    @keyframes turn{
        0%{-webkit-transform:rotate(0deg);}
        25%{-webkit-transform:rotate(90deg);}
        50%{-webkit-transform:rotate(180deg);}
        75%{-webkit-transform:rotate(270deg);}
        100%{-webkit-transform:rotate(360deg);}
    }
}
.music-s{
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 25px;
    
}
.inclined{
    display: inline-block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: -6px;
    left: -6px;
    transform:rotate(45deg);
    -ms-transform:rotate(45deg); /* Internet Explorer */
    -moz-transform:rotate(45deg); /* Firefox */
    -webkit-transform:rotate(45deg); /* Safari 和 Chrome */
    -o-transform:rotate(45deg); /* Opera */
    border-right: 1px solid #000;
}
</style>