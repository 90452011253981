<template>
    <div>
          <div class="music-xsz">
            <music />
        </div>
        <div class="content">
            <div class="content-up">
                <p>行驶证正面：</p>
                 <div v-if="!isWechat">
                    <van-uploader v-model="fileList1" :after-read="uploadershang(1)" :before-delete="deleteimg(1)" preview-size="100%" :max-count="1">
                        <img style="width:100%" src="../../assets/zhanwu.jpg" alt="">
                    </van-uploader>
                 </div>
                 <div v-else>
                    <img v-if="!frontUrl" style="width:100%" src="../../assets/zhanwu.jpg" alt="" @click="WeChooseImage(1,'front')">

                    <div v-else style="width:100%">
                        <van-image class="vanImage" style="width:100%"  :src="frontUrl" @click="bigImg(frontUrl)" >
                            <van-icon class="iconX" name="close" @click.stop="delImgHan('front')" />
                        </van-image>
                    </div>
                </div>
            </div>
            <div class="content-up">
                <p>行驶证反面：</p>
                <div v-if="!isWechat">
                    <van-uploader v-model="fileList2" :after-read="uploadershang(2)" :before-delete="deleteimg(2)" preview-size="100%" :max-count="1">
                        <img style="width:100%" src="../../assets/zhanwu.jpg" alt="">
                    </van-uploader>
                </div>
                <div v-else>

                    <img v-if="!reverseUrl" style="width:100%" src="../../assets/zhanwu.jpg" alt="" @click="WeChooseImage(1,'reverse')">
                
                    <div v-else style="width:100%">
                        <van-image class="vanImage" style="width:100%"  :src="reverseUrl" @click="bigImg(reverseUrl)" >
                            <van-icon class="iconX" name="close" @click.stop="delImgHan('reverse')" />
                        </van-image>
                    </div>
                </div>
            </div>
            <div class="deng">
                <p class="deng-p">登记证：</p>
                

                <div class="imgbox"  v-for="(item,index) in WXimg" :key="index">
                    <van-image class="vanImage" width="50%"  height="100"  :src="item" @click="bigImgs(item)" >
                        <!-- <van-icon class="iconX" name="close" @click.stop.prevent="delImg(index)" /> -->
                        <van-button type="info" size="small" @click.stop="Discern(index)">识别</van-button>
                        <van-button type="danger" size="small" @click.stop="delImg(index)">删除</van-button>
                        <br>
                    </van-image>
                </div>
                <div class="uploaderp">
                    <van-uploader :after-read="uploadershang(3)" multiple>
                        <van-button icon="plus" type="primary" @click="uploadershang(3)">上传登记证</van-button>
                    </van-uploader>
                </div>
            </div>
        </div>
        <div class="info">
            <p>行驶证信息</p>
            <van-cell-group>
                <van-field label="车牌号码：" v-model="isData.number" />
                <van-field label="车辆类型：" v-model="isData.CarGenreText" />
                <van-field label="所有人：" v-model="isData.owner" />
                <van-field label="住址：" v-model="isData.address" />
                <van-field label="使用性质：" v-model="isData.natureText" />
                <van-field label="型号：" v-model="isData.model" />
                <van-field label="品牌：" v-model="isData.brand" />
                <van-field label="车架号：" v-model="isData.identificationCode" />
                <van-field label="发动机型号：" v-model="isData.engineModel" />
                <van-field label="发动机号码：" v-model="isData.engineCode" />
                <van-field label="注册日期：" v-model="isData.recordDate" />
                <van-field label="发证日期：" v-model="isData.openingDate" />
                <!-- <van-field label="登记证日期：" v-model="isData.date" /> -->
            </van-cell-group>
            <van-cell-group>
                <van-field label="动力类别：" v-model="versoData.typeOfFuel" />
                <van-field label="核定载客数：" v-model="versoData.numPeople" />
                <van-field label="整备质量：" v-model="versoData.curbWeight" />
                <van-field label="排量：" v-model="versoData.displacement" />
                <van-field label="功率：" v-model="versoData.power" />
                
                <van-field label="外廓尺寸：" :value="`${isgabarite1(gabarite1,versoData.gabarite1)}  X  ${isgabarite2(gabarite2,versoData.gabarite2)} X  ${isgabarite3(gabarite3,versoData.gabarite3)}`" @focus="isPopup" />
            </van-cell-group>
        </div>
         <van-button class="content-btn" type="info" size="large" @click="submit">完成</van-button>
        <van-popup  :closeable="true"  v-model="show" class="popup" :close-on-click-overlay="false">
              <p style="text-align: center; ">修改长宽高</p>
              <hr>
              <van-field label="长：" v-model="gabarite1" />
              <van-field label="宽：" v-model="gabarite2" />
              <van-field label="高：" v-model="gabarite3" />
              <div class="btn-ok">
                  <!-- <van-button @click="show= false">取消</van-button> -->
                  <van-button type="info" @click="isSubmit">确定</van-button>
              </div>
        </van-popup>
        <van-popup  :closeable="true"  v-model="voiceShow" class="popup" :close-on-click-overlay="false">
              <p style="text-align: center; ">是否语音播报</p>
              <hr>
              
              <div class="btn-ok">
                  <van-button @click="voiceShow= false">取消</van-button>
                  <van-button type="info" @click="yuying">确定</van-button>
              </div>
        </van-popup>
        <audio :src="voice" id="audio"  ref="au"></audio>
         <van-popup
            v-model="CarInfoShow"
            closeable
            :close-on-click-overlay="false"
            position="bottom"
            :style="{height: '100%',width:'100%'}">
            <div class="title_car">车辆列表</div>
            <ObtainCarInfo ref="ObtainRef" :repetition="repetition" @colosepopup="colosepopup" :vin=" this.isData.identificationCode" @comeWei="comeWei" @upDate="upDate" />
            
        </van-popup>
    </div>
</template>

<script>
import { Toast,ImagePreview } from 'vant';
import { is_post } from "../../Http/api.js";
import { deleteCookie } from '@/utils/env'
import music from '../../components/music.vue'
import { encipherMent, encryptDecode } from '@/utils/encrypt'
import ObtainCarInfo from '@/components/ObtainCarInfo'
import {PlateNum} from '@/api/carInfo'

    export default {
        components: {
            music,ObtainCarInfo
        },
        data() {
            return {
                fileList1: [],//行驶证正面照片
                fileList2: [],//反面照片
                isData:{},//证明识别数据
                versoData:{},//反面数据
                frontUrl:"",//证明照片
                reverseUrl:'',//反面照片链接
                show:false,//修改长宽高
                gabarite1:0,//涨
                gabarite2:0,//宽
                gabarite3:0,//高
                voice:'',//语音链接
                voiceShow:false,//打开点击语音
                wximagelocalids:[],//判断上传张数
                toast:null,//转圈圈
                CarInfoShow:false,
                repetition:[],
                fileList3:[],
                imageFile:[],//多张上传
                WXimg:[],//多张上传
                registratioCard:'',
            }
        },
        mounted () {
            if(this.isWechat){
                this.wcConfig();
            }
        },
        watch: {
            wximagelocalids:{
                handler(newValue, oldValue){
                     if(newValue.length > 0 ){
                    this.toast = Toast.loading({
                        duration: 0, // 持续展示 toast
                        forbidClick: true,
                        message: "上传中..."
                    });
                    this.wxUpload(newValue[0])
                }else{
                    this.toast.clear();
                }
                },
                deep: true,
            },
    
            isData: {
                immediate: true,
                deep: true,
                handler(newValue, oldValue) {
                    console.log(newValue)
                    if(newValue.vin){
                        this.isData.identificationCode = newValue.vin
                    }
                    if(newValue.typeOfFuel){
                        this.versoData.typeOfFuel = newValue.typeOfFuel
                    }
                    if(newValue.numPeople){
                        this.versoData.numPeople = newValue.numPeople
                    }
                    if(newValue.curbWeight){
                        this.versoData.curbWeight = newValue.curbWeight
                    }
                    if(newValue.displacement){
                        this.versoData.displacement = newValue.displacement
                    }
                    if(newValue.power){
                        this.versoData.power = newValue.power
                    }
                    if(newValue.gabarite1){
                        this.versoData.gabarite1 = newValue.gabarite1
                    }
                    if(newValue.gabarite2){
                        this.versoData.gabarite2 = newValue.gabarite2
                    }
                    if(newValue.gabarite3){
                        this.versoData.gabarite3 = newValue.gabarite3
                    }
                }
            }
            
        },
        computed: {
            isgabarite1() {
                return (n,e)=>{
                   
                    let a = 0
                    if(n){
                        a = n
                    }else if(e){
                        a=e
                    }
                    return a
                }
            },
            isgabarite2() {
                return (n,e)=>{
                   
                    let a = 0
                    if(n){
                        a = n
                    }else if(e){
                        a=e
                    }
                    return a
                }
            },
            isgabarite3() {
                return (n,e)=>{
                   
                    let a = 0
                    if(n){
                        a = n
                    }else if(e){
                        a=e
                    }
                    return a
                }
            },
           
            
        },
        methods: { 
            bigImgs(img){
                console.log(img)
                ImagePreview([img])
            },
            delImg(index){
                console.log(123)
                this.WXimg.splice(index,1)
            },
            beforeRead(file){
                if(file instanceof Array){
                    for(let i=0;i<file.length;i++){
                        if ((file[i].type !== 'image/jpeg') && (file[i].type !== 'image/png')) {
                            Toast('请上传 jpg/png 格式图片');
                            return false;
                        }
                        return true;
                    }
                
                }else{
                    if((file.type !== 'image/jpeg') && (file.type !== 'image/png')) {
                        Toast('请上传 jpg/png 格式图片');
                        return false;
                    }
                    return true;
                }
           },
           deleteimg2(file,datail){
            
                this.imageFile.forEach((item,index)=>{
                    if(index == datail.index){   // 判断删除的下标是否正确
                            this.imageFile.splice(index,1) // 使用遍历找到的index去数组里面删除
                        }
                })
                this.WXimg.forEach((item,index)=>{
                    if(index == datail.index){   // 判断删除的下标console.log(this.WXimg)
                        this.WXimg.splice(index,1) // 使用遍历找到的index去数组里面删除
                    }
                })
            },
            Discern(index){
                let url = 'index.php/index/Image_Recognition/vehicleRegistrationOcr'
                this.recognition(url,{path:this.WXimg[index]},1)
            },
            isPlay(n){
                var toast = Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: "加载中..."
                });
               
                let text = `车牌号：${n.number}，所有人：${n.owner}，车辆识别代码：${n.identificationCode}`
                this.$http.post('index.php/wechat/makeYuYin',{text}).then(res=>{
                    
                    this.voice = res.data.data
                    toast.clear()//清除加载效果
                    var u = navigator.userAgent;
                    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
                    if(isiOS){
                        this.voiceShow = true
                    }else{
                        this.$nextTick(()=>{
                            this.$refs.au.play()
                        })
                    }
                })
            },
            yuying(){
                this.$refs.au.play()
                 this.voiceShow = false
            },
           
            isPopup(){
                this.show=true
                console.log(this.versoData)
                this.gabarite1  = this.versoData.gabarite1 
                this.gabarite2  = this.versoData.gabarite2 
                this.gabarite3  = this.versoData.gabarite3 
            },
           isSubmit(){
               this.versoData.gabarite1 = this.gabarite1 
               this.versoData.gabarite2 = this.gabarite2 
               this.versoData.gabarite3 = this.gabarite3 
               this.show=false
           },
             colosepopup(){
                 this.CarInfoShow = false
            },
            comeWei(){
                this.CarInfoShow = false
                this.onSubmit()
            },
            upDate(e){
                this.isData.identificationCode = e[0]
                this.CarInfoShow = false
                this.onSubmit()
            },
            async submit(){
                if(this.isData.number && this.isData.identificationCode){
                    const {data} = await PlateNum({value:this.isData.number}).then(res=>res)
                    let arr =  data.data
                    if(arr.length==0){
                        this.onSubmit()
                    }else if(arr.length==1){
                        if(arr[0].carNumberPlate == this.isData.number && arr[0].vin == this.isData.identificationCode){
                            this.onSubmit()
                        }else{
                            this.repetition = arr
                            this.CarInfoShow = true
                            this.$nextTick(()=>{
                                this.$refs.ObtainRef.checkbox = []
                            })
                        }
                    }else if(arr.length > 1){
                        this.repetition = arr
                        this.CarInfoShow = true
                        this.$nextTick(()=>{
                                this.$refs.ObtainRef.checkbox = []
                            })
                    }
                }else {
                    this.$toast.fail('请填写车牌或跟车架号。')
                }
            },
            async onSubmit(){
                try{
                    let obj = {...this.isData,...this.versoData,reverseUrl:this.reverseUrl,frontUrl:this.frontUrl,registratioCard:this.WXimg}
                     let data = encipherMent( JSON.stringify(obj))
                    let param = new URLSearchParams();
                    param.append("value",data);
                    const res = await is_post('index.php/vehicles_upload/vehicleInformation',param).then(res=>res)
                    this.gouot()
                    this.$toast.success(res.msg)
                }
                catch{}
            },
             gouot() {
                this.$router.push('/Recognition')
            },
            //删除
            deleteimg(i){
                return file=>{
                    if(i===1){
                        this.isData = {}
                        return true
                    }else if(i===2){
                        this.versoData = {}
                        return true
                    }
                }
                
                
            },
            uploadershang(itm){
                return file =>{
                    file.status = "uploading";
                    file.message = "上传中...";
                    if(file instanceof Array){
                        file.forEach(item=>{
                            this.upImages(itm,item)
                        })
                    }else{
                        this.upImages(itm,file)
                    }
                }
            },
        //     //上传照片
           upImages(itm,file){
              
                var toast = Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: "加载中..."
                });
                    let params = new FormData(); //创建form对象
                    params.append("file", file.file);
                   this.$http.post("/index.php/index/upload/index", params)
                    .then((res) => {
                        
                    if (res.data.code == 0) {
                         toast.clear()//清除加载效果
                        //判断行驶证正面还是反面
                        if(itm == 1) {
                            let url = 'index.php/index/Image_Recognition/vehicleLicenseFront'
                            this.frontUrl = res.data.data
                        
                            this.recognition(url,{path:res.data.data},1)
                        }else if(itm == 2){ 
                            let url = 'index.php/index/Image_Recognition/vehicleLicenseBack'
                            this.reverseUrl = res.data.data
                            this.recognition(url,{path:res.data.data},'verso')
                        }else if(itm == 3){ 
                            this.WXimg.push(res.data.data)
                        }
                        file.status = "done";
                    } else if(res.data.code == -1){
                        deleteCookie('token')
                        
                        this.$toast.fail(res.data.msg)
                        this.$router.push('/')
                    }else{
                        // file.status = "failed";
                        // file.message = "上传失败";
                        if(itm == 1){
                            this.frontUrl = ''
                            this.fileList1 = []
                        }else if(itm == 2){
                            this.reverseUrl = ''
                            this.fileList2 = []
                        }else if(itm == 3){
                            this.fileList3 = []
                            this.registratioCard = '[]'
                        }
                         toast.clear()//清除加载效果
                        this.$toast.fail(res.data.msg)
                    }
                    }).catch((err)=>{
                        // file.status = "failed";
                        // file.message = "上传失败";

                        if(itm == 1){
                            this.frontUrl = ''
                            this.fileList1 = []
                        }else if(itm == 2){
                            this.reverseUrl = ''
                            this.fileList2 = []
                        }else if(itm == 3){
                            this.fileList3 = []
                            this.registratioCard = '[]'
                        }
                         toast.clear()//清除加载效果
                        this.$toast.fail('网络开小差了，请稍后重试！')
                    })
               
           },
        },
    }
</script>

<style lang="scss" scoped>
.uploaderp{
    margin-top: 20px;
}
.content{
    padding: 10px;
    margin-top: 20px;
    text-align: center;
    .content-up{
        margin-top: 20px;
        padding: 10px;
      
    }
}

.info{
    margin-top: 20px;
    padding: 10px;
}

.van-uploader__preview-image{
    width: 100% ;
    height: 100px ;
}
  .popup{
    width: 80%;
    padding: 20px;
    .btn-ok{
        width: 100%;
        display: flex;
        .van-button{
            flex: 1;
        }
    }
}
.music-xsz{
    position: relative;
}
 .vanImage{
    position: relative;
    margin-right: 10px;
    margin-top: 10px;
        .iconX{
        position: absolute;
        top: 0;
        right: 0;
        font-size: 25px;
        background: #fff;
        color: #000;
    }
    .van-button{
        margin: 0 10px;
    }
}
.deng{
    .deng-p{
        text-align: left;
    }
}
.preview-cover {
    position: absolute;
    bottom: 0;
    box-sizing: border-box;
    width: 100%;
    padding: 4px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    background: rgba(0, 0, 0, 0.5);
  }
</style>